import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useSearchParams,
  useLocation
} from "react-router-dom";
import axios from 'axios';

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/sso/ping" element={<PingResponse />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </Router>
  );
}

const SSO_GENERATE_URI = `${process.env.REACT_APP_HARBR_API_URL}/v1/ssoGenerateUri`;
const SSO_AUTHENTICATE_URI = `${process.env.REACT_APP_HARBR_API_URL}/v1/ssoAuthenticateUser`;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const PING_CALLBACK_PATH = '/sso/ping';
const CLAUTH_OIDC_PROVIDER = process.env.REACT_APP_CLAUTH_OIDC_PROVIDER;
const EMPLOYEE_OIDC_PROVIDER = process.env.REACT_APP_EMPLOYEE_OIDC_PROVIDER;

const Login = (props) => {

  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState();
  const [search, ] = useSearchParams();

  useEffect(() => {
    const providers = {
      employee: {
        // TODO:
        // code: "oidc.cl-test-oidc-cl-auth"
        code: `${EMPLOYEE_OIDC_PROVIDER}`
      },
      clauth: {
        //code: "oidc.cl-test-oidc-cl-auth"
        code: `${CLAUTH_OIDC_PROVIDER}`
      },
    };
    
    const selectedProvider = search.get('provider');
    if (providers[selectedProvider]) {
      const providerId = providers[selectedProvider].code;
      const ssoContinueUri = `${window.location.origin}${PING_CALLBACK_PATH}`;

      setRedirecting(true);
      
      axios.get(SSO_GENERATE_URI, {
        params: {
          providerId,
          ssoContinueUri
        }
      }).then((response) => {
        const {redirectUri, sessionId} = response.data;
        if (!redirectUri || !sessionId) {
          setError('Login request failed');
          return;
        }
        sessionStorage.setItem('sessionId', sessionId);
        window.location.replace(redirectUri);
      }).catch(() => {
        setError('Unable to generate login request');
      });
    }
  }, [search]);

  if (error) {
    return (
      <div>
        <h2>Error</h2>
        {error}
      </div>
    );
  }

  if (redirecting) {
    return (
      <div>
        <h2>Redirecting...</h2>
      </div>
    );
  }

  return (  
    <h2>Unknown Provider</h2>
  );
};

const PingResponse = (props) => {

  const location = useLocation();
  const [error, setError] = useState();
  const [pingToken, setPingToken] = useState('');
  const [pingState, setPingState] = useState('');
  const [harbrToken, setHarbrToken] = useState('');
  const [harbrRefreshToken, setHarbrRefreshToken] = useState('');

  useEffect(() => {
      const { id_token = '', state = '' } = (location.hash || '')
          .replace('#', '')
          .split('&')
          .reduce((prev, item) => {
              return Object.assign(
                  {
                      [item.split('=')[0]]: decodeURIComponent(
                          item.split('=')[1]
                      ),
                  },
                  prev
              );
          }, {});

      setPingToken(id_token);
      setPingState(state);

      if (id_token && state) {
        axios.post(SSO_AUTHENTICATE_URI, {
          idToken: id_token,
          state,
          sessionId: sessionStorage.getItem('sessionId')
        }).then((response) => {
          const {idToken, refreshToken} = response.data;
          setHarbrToken(idToken);
          setHarbrRefreshToken(refreshToken);

          if (REDIRECT_URI && idToken) {
            const redirectUrl = `${REDIRECT_URI}#id_token=${idToken}`;
            //window.location.replace(redirectUrl);
          }
        }).catch(() => {
          setError('Unable to generate login request');
        });
      }
  }, [location.hash]);

  if (error) {
    return (
      <div>
        <h2>Error</h2>
        {error}
      </div>
    );
  }

  return (
    <div>
      <h2>Response</h2>
      <div>{location.hash}</div>
      <div>Ping Token: {pingToken}</div>
      <div>Ping State: {pingState}</div>
      <div>Harbr Token: {harbrToken}</div>
      <div>Harbr Refresh Token: {harbrRefreshToken}</div>
      //<div>SSO_GENERATE_URI Env Var Value: {SSO_GENERATE_URI}</div>
      //<div>SSO_AUTHENTICATE_URI Env Var Value: {SSO_AUTHENTICATE_URI}</div>
      //<div>REDIRECT_URI Env Var Value: {REDIRECT_URI}</div>
      //<div>PING_CALLBACK_PATH Value : {PING_CALLBACK_PATH}</div>
      //<div>CLAUTH_OIDC_PROVIDER Value : {CLAUTH_OIDC_PROVIDER}</div>
      //<div>EMPLOYEE_OIDC_PROVIDER Value : {EMPLOYEE_OIDC_PROVIDER}</div>
    </div>
  );
};

function NoMatch() {
  return <h2>404</h2>;
}